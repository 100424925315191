<template lang="pug">
.message
  //v-snackbar(v-model="snackbar" :color="type" :top="true" :right="true" :timeout="-1") {{ message }}
  //template(v-sloat:action="{ attrs }")
  //  v-btn(color="pink" text v-bind="attrs" @click="close") Close
</template>

<script>
export default {
  data () {
    return {
      visible: false,
      title: '',
      message: '',
      duration: 2000,
      type: '',
      showClose: true,
      customClass: '',
      iconClass: '',
      onClose: null,
      onClick: null,
      closed: false,
      verticalOffset: 0,
      timer: null,
      dangerouslyUseHTMLString: false,
      position: 'top-right',
    }
  },
  computed: {},
  watch: {
    closed (newVal) {
      if (newVal) {
        this.visible = false
        this.$el.addEventListener('transitionend', this.destroyElement)
      }
    },
  },
  methods: {
    destroyElement () {
      this.$el.removeEventListener('transitionend', this.destroyElement)
      this.$destroy(true)
      this.$el.parentNode.removeChild(this.$el)
    },
    click () {
      if (typeof this.onClick === 'function') {
        this.onClick()
      }
    },
    close () {
      this.closed = true
      if (typeof this.onClose === 'function') {
        this.onClose()
      }
    },
    clearTimer () {
      clearTimeout(this.timer)
    },
    startTimer () {
      if (this.duration > 0) {
        this.timer = setTimeout(() => {
          if (!this.closed) {
            this.close()
          }
        }, this.duration)
      }
    },
    keydown (e) {
      if (e.keyCode === 46 || e.keyCode === 8) {
        this.clearTimer()
      } else if (e.keyCode === 27) {
        // esc
        if (!this.closed) {
          this.close()
        }
      } else {
        this.startTimer()
      }
    },
  },
  mounted () {
    if (this.duration > 0) {
      this.timer = setTimeout(() => {
        if (!this.closed) {
          this.close()
        }
      }, this.duration)
    }
    document.addEventListener('keydown', this.keydown)
  },
  beforeDestroy () {
    document.removeEventListener('keydown', this.keydown)
  },
}
</script>
