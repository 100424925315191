// import moment from 'moment'
// import Vue from 'vue'
import moment from 'moment-timezone'
import message from './message.js'
import xid from './xid/xid.js'
import { api } from '@/api/index.js'

// const variable
const c = {
  // 语言值
  LanguageItem: [
    {
      label: 'English',
      value: 'en',
      name: 'en',
      label_cn: '英语'
    },
    {
      label: '简体中文',
      value: 'zh_cn',
      name: 'zh_cn',
      label_cn: '简体中文'
    }
  ],
  DataflowNodeTypes: [
    {
      type: 'trigger',
      icon: 'el-icon-link',
      name: '触发',
      default: {
        schema_id: '',
        event_type: '', // insert, update, delete, workflow
      },
    },
    {
      type: 'get',
      icon: 'el-icon-document-checked',
      name: '获取',
      default: {
        schema_id: '',
        filters: [[{
          column_id: '',
          ops: '=',
          value_type: 'value',
          value: '',
        }]]
      },
    },
    {
      type: 'select',
      icon: 'el-icon-search',
      name: '查询',
      default: {
        schema_id: '',
        column_id: '',
        limit: 100,
        filters: [[{
          column_id: '',
          ops: '=',
          value_type: 'value',
          value: '',
        }]],
        nodes: [],
      },
    },
    {
      type: 'insert',
      icon: 'el-icon-document-add',
      name: '插入',
      default: {
        schema_id: '',
        column_values: [{
          column_id: '',
          ops: '=',
          value_type: 'value',
          value: '',
        }],
      },
    },
    {
      type: 'update',
      icon: 'el-icon-document-checked',
      name: '更新',
      default: {
        node_id: '',
        column_values: [{
          column_id: '',
          ops: '=',
          value_type: 'value',
          value: '',
        }],
      },
    },
    {
      type: 'delete',
      icon: 'el-icon-document-remove',
      name: '删除',
      default: {
        node_id: '',
      },
    },
    {
      type: 'branch',
      icon: 'el-icon-guide',
      name: '分支',
      default: {
        node_id: '',
        result_exist_check: false,
        branches: [{
          filters: [[{
            column_id: '',
            ops: '=',
            value_type: 'value',
            value: '',
          }]],
          nodes: [],
        }, {
          filters: [[{
            column_id: '',
            ops: '=',
            value_type: 'value',
            value: '',
          }]],
          nodes: [],
        }],
      },
    },
  ],
}

function isWeiXin () {
  var ua = window.navigator.userAgent.toLowerCase()
  if (ua.indexOf('micromessenger') > -1) {
    return true // 是微信端
  } else {
    return false
  }
}

function isMobile () {
  if ((navigator.userAgent.match(/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i))) {
    return true // 手机端
  } else {
    return false // alert('PC端')
  }
}

function formatToDate (createdTime) {
  // return moment(createdTime).format('YYYY-MM-DD')
  // return moment.utc(createdTime).tz('Asia/Shanghai').format('YYYY-MM-DD')
  return moment.utc(createdTime).format('YYYY-MM-DD')
}

function getDate2 () {
  // return moment(createdTime).format('YYYY-MM-DD')
  // return moment.utc(createdTime).tz('Asia/Shanghai').format('YYYY-MM-DD')
  return moment.utc().tz('Asia/Shanghai').format('YYYY-MM-DD')
}

function getDate () {
  // return moment(createdTime).format('YYYY-MM-DD')
  // return moment.utc(createdTime).tz('Asia/Shanghai').format('YYYY-MM-DD')
  return moment.utc().tz('Asia/Shanghai').format('YYYY-MM-DD HH:mm:ss')
}

function formatToTime (createdTime) {
  // return moment.utc(createdTime).tz('Asia/Shanghai').format('YYYY-MM-DD HH:mm:ss')
  return moment.utc(createdTime).format('YYYY-MM-DD HH:mm:ss')
  // return moment.utc(createdTime).utcOffset(8).format('YYYY-MM-DD HH:mm:ss')
}

function formatTime (createdTime, formatStr) {
  return moment.utc(createdTime).tz('Asia/Shanghai').format(formatStr)
}

function deepCopy (target) {
  // 定义一个变量
  let result
  // 如果当前需要深拷贝的是一个对象的话
  if (typeof target === 'object') {
    // 如果是一个数组的话
    if (Array.isArray(target)) {
      // 将result赋值为一个数组，并且执行遍历
      result = []
      for (const i in target) {
        // 递归克隆数组中的每一项
        result.push(deepCopy(target[i]))
      }
    // 判断如果当前的值是null的话；直接赋值为null
    } else if (target === null) {
      result = null
    // 判断如果当前的值是一个RegExp对象的话，直接赋值
    } else if (target.constructor === RegExp) {
      result = target
    // 否则是普通对象，直接for in循环，递归赋值对象的所有值
    } else {
      result = {}
      for (const i in target) {
        result[i] = deepCopy(target[i])
      }
    }
  // 如果不是对象的话，就是基本数据类型，那么直接赋值
  } else {
    result = target
  }

  // 返回最终结果
  return result
}

// 递归获取树 [{id: 1, parentId: null, name: '11'}, {id: 2, parentId: null, name: '22'}, {id: 3, parentId: 1, name: '33'}, {id: 4, parentId: 1, name: '44'}]
function getTreeData (data, idField, parentField, childField) {
  // 删除 所有 children,以防止多次调用
  if (!childField) childField = 'childData'
  data.forEach(function (item) {
    delete item[childField]
  })

  // 将数据存储为 以 id 为 KEY 的 map 索引数据列
  var map = {}
  data.forEach(function (item) {
    map[item[idField]] = item
  })
  //        console.log(map);
  var val = []
  data.forEach(function (item) {
    // 以当前遍历项，的pid,去map对象中找到索引的id
    var parent = map[item[parentField]]
    // 如果找到索引，那么说明此项不在顶级当中,那么需要把此项添加到，他对应的父级中
    if (parent) {
      if (!parent[childField]) parent[childField] = []
      parent[childField].push(item)
    } else {
      // 如果没有在map中找到对应的索引ID,那么直接把 当前的item添加到 val结果集中，作为顶级
      val.push(item)
    }
  })
  return val
}

function schemaColumnsToLine (columns, authColumns = []) {
  let count = 0
  const lineColumns = []
  let line = []

  for (const column of columns) {
    count += 1

    let authColumn = null
    for (const auth of authColumns) {
      if (column.id === auth.id) {
        authColumn = auth
      }
    }

    column.workflow_auth_type = 'edit'
    if (authColumn && authColumn.auth) {
      column.workflow_auth_type = authColumn.auth
    }

    // new line
    if (column.new_line) {
      if (line.length > 0) {
        lineColumns.push([])
        lineColumns.push(line)
      }
      line = []
      count = 0
    } else if (column.types === 'table') {
      if (line.length > 0) {
        lineColumns.push([])
        lineColumns.push(line)
      }

      lineColumns.push([])
      lineColumns.push([column])
      line = []
      count = 0
      continue
    } else if (count > 4) {
      if (line.length > 0) {
        lineColumns.push([])
        lineColumns.push(line)
      }
      line = []
      count = 0
    }

    line.push(column)
  }
  if (line.length > 0) {
    lineColumns.push([])
    lineColumns.push(line)
  }
  lineColumns.push([])

  return lineColumns
}

function getWorkflowAuthColumns (columns, authColumns = []) {
  const schemaColumnAuth = []
  columns.forEach(column => {
    let authColumn = null
    authColumns.forEach(auth => {
      if (column.id === auth.id) {
        authColumn = auth
      }
    })

    if (column.type === 'detail' && column.columns) {
      let children = []
      if (authColumn) {
        children = getWorkflowAuthColumns(column.columns, authColumn.children)
      } else {
        children = getWorkflowAuthColumns(column.columns)
      }

      let authType = 'edit'
      if (authColumn) {
        authType = authColumn.auth
      }

      schemaColumnAuth.push({
        id: column.id,
        name: column.name,
        type: column.type,
        children: children,
        auth: authType,
      })
    } else {
      let authType = 'edit'
      if (authColumn) {
        authType = authColumn.auth
      }

      schemaColumnAuth.push({
        id: column.id,
        name: column.name,
        type: column.type,
        children: [],
        auth: authType,
      })
    }
  })
  return schemaColumnAuth
}

function getWorkflowFilters (columns, filters = []) {
  const schemaColumnAuth = []
  for (const conditions of filters) {
    for (const condition of conditions) {
      const column = getSchemaColumnByID(columns, condition.column_id)
      if (column) {
        condition.column = column
        condition.column_type = column.type
      }
    }
  }
  return schemaColumnAuth
}

function getWorkflowClearFilters (filters) {
  const clearFilters = []
  for (const conditions of filters) {
    const clearConditions = []
    for (const condition of conditions) {
      if (condition.column_id === '' || condition.ops === '') {
        continue
      }
      clearConditions.push({
        column: condition.column,
        id: condition.id,
        name: condition.name,
        type: condition.type,
        types: condition.types,
        ops: condition.ops,
        value: condition.value,
      })
    }
    if (clearConditions.length > 0) {
      clearFilters.push(clearConditions)
    }
  }
  return clearFilters
}

function getDataflowFilters (columns, filters = []) {
  const schemaColumnAuth = []
  for (const conditions of filters) {
    for (const condition of conditions) {
      const column = getSchemaColumnByID(columns, condition.column_id)
      if (column) {
        condition.column = column
        condition.column_type = column.type
      }
    }
  }
  return schemaColumnAuth
}

function getDataflowClearFilters (filters) {
  const clearFilters = []
  for (const conditions of filters) {
    const clearConditions = []
    for (const condition of conditions) {
      if (condition.column_id === '' || condition.ops === '') {
        continue
      }
      clearConditions.push({
        column_id: condition.column_id,
        ops: condition.ops,
        value: condition.value,
      })
    }
    if (clearConditions.length > 0) {
      clearFilters.push(clearConditions)
    }
  }
  return clearFilters
}

function getSchemaFlattenColumns (columns, includeDetail = true, parentColumn) {
  let flattenColumns = []
  for (const column of columns) {
    if (parentColumn) {
      column.flatten_id = (parentColumn.flatten_id && parentColumn.flatten_id !== '' ? parentColumn.flatten_id + '.' : parentColumn.id && parentColumn.id !== '' ? parentColumn.id + '.' : '') + column.id
      column.flatten_name = (parentColumn.flatten_name && parentColumn.flatten_name !== '' ? parentColumn.flatten_name + '.' : parentColumn.name && parentColumn.name !== '' ? parentColumn.name + '.' : '') + column.name
      column.flatten_title = (parentColumn.flatten_title && parentColumn.flatten_title !== '' ? parentColumn.flatten_title + '.' : parentColumn.title && parentColumn.title !== '' ? parentColumn.title + '.' : '') + column.title
    } else {
      column.flatten_id = column.id
      column.flatten_name = column.name
      column.flatten_title = column.title
    }

    if (column.type === 'detail') {
      if (includeDetail) {
        const subColumns = getSchemaFlattenColumns(column.columns, includeDetail, column)
        flattenColumns = flattenColumns.concat(subColumns)
      }
    } else {
      flattenColumns.push(column)
    }
  }
  return flattenColumns
}

function getSchemaDetailColumns (columns) {
  const detailColumns = []
  for (const column of columns) {
    if (column.type === 'detail') {
      detailColumns.push(column)
    }
  }
  return detailColumns
}

async function getNodeResultFlttenSchemaColumn (nodes, nodeID) {
  let flattenResultNodes = []
  for (let i = 0; i < nodes.length; i++) {
    const node = nodes[i]
    if (node.id === nodeID) {
      return flattenResultNodes
    }

    if (node.type === 'branch') {
      if (node.settings && node.settings.branches) {
        for (let i = 0; i < node.settings.branches.length; i++) {
          const branch = node.settings.branches[i]
          if (branch.nodes) {
            const subResult = await getNodeResultFlttenSchemaColumn(branch.nodes, nodeID)
            if (subResult) {
              flattenResultNodes = flattenResultNodes.concat(subResult)
              return flattenResultNodes
            }
          }
        }
      }
    } else if (node.settings && node.settings.schema_id) {
      const res = await api.getSchemaSync(node.settings.schema_id)
      const schema = res.data
      const columns = res.data.config.columns

      const flattenID = '@' + node.id + '#' + schema.id

      let children = []
      let selectColumn = null
      if (node.type === 'select' && node.settings.column_id && node.settings.column_id !== '') {
        for (i = 0; i < columns.length; i++) {
          const column = columns[i]
          if (column.id === node.settings.column_id) {
            selectColumn = column
          } else {
            delete columns[i]
          }
        }
        children = deepCopy(getSchemaFlattenColumns(deepCopy(columns), true, { id: flattenID, name: '', title: '' }))
      } else {
        children = deepCopy(getSchemaFlattenColumns(deepCopy(columns), false, { id: flattenID, name: '', title: '' }))
      }

      flattenResultNodes.push({
        node_id: node.id,
        node_name: node.name,
        node_type: node.type,
        schema_id: schema.id,
        schema_name: schema.name + (selectColumn != null ? '.' + selectColumn.name : ''),
        flatten_id: flattenID,
        flatten_name: node.name + '.' + schema.name,
        children: children,
      })

      if (node.type === 'select' && node.settings && node.settings.nodes) {
        const subResult = await getNodeResultFlttenSchemaColumn(node.settings.nodes, nodeID)
        if (subResult) {
          flattenResultNodes = flattenResultNodes.concat(subResult)
          return flattenResultNodes
        }
      }
    }
  }
}

// async function getNodeSchemas (nodes, nodeID) {
//   let nodeSchemas = []
//   for (let i = 0; i < nodes.length; i++) {
//     const node = nodes[i]
//     if (node.id === nodeID) {
//       return nodeSchemas
//     }
//
//     if (node.type === 'branch') {
//       if (node.settings && node.settings.branches) {
//         for (let i = 0; i < node.settings.branches.length; i++) {
//           const branch = node.settings.branches[i]
//           if (branch.nodes) {
//             const subResult = await getNodeSchemas(branch.nodes, nodeID)
//             if (subResult) {
//               nodeSchemas = nodeSchemas.concat(subResult)
//               return nodeSchemas
//             }
//           }
//         }
//       }
//     } else if (node.settings && node.settings.schema_id) {
//       const res = await api.getSchemaSync(node.settings.schema_id)
//       const schema = res.data
//       nodeSchemas.push({
//         node_id: node.id,
//         node_name: node.name,
//         node_type: node.type,
//         schema_id: schema.id,
//         schema_name: schema.name,
//       })
//
//       if (node.type === 'select' && node.settings && node.settings.nodes) {
//         const subResult = await getNodeSchemas(node.settings.nodes, nodeID)
//         if (subResult) {
//           nodeSchemas = nodeSchemas.concat(subResult)
//           return nodeSchemas
//         }
//       }
//     }
//   }
// }

function getSchemaColumnByID (columns, id) {
  for (const column of columns) {
    if (column.id === id) {
      return column
    }

    if (column.type === 'detail' && column.columns) {
      return getSchemaColumnByID(column.columns, id)
    }
  }
}

function getFormulaColumn (formulaStr) {
  // const fieldRegex = /(?<={).+(?=})/
  const fieldRegex = new RegExp('(?<={).+(?=})')

  const matchResult = formulaStr.match(fieldRegex)
  if (matchResult.length !== 1) {
    return
  }

  return matchResult[0].split('.')
}

function getFormulaColumnValue (formulaStr, data) {
  const columnNameList = getFormulaColumn(formulaStr)
  let columnData = data
  for (const columnName of columnNameList) {
    // console.log('columnData1', columnData, columnName)
    if (columnName in columnData) {
      // console.log('columnData', columnData, columnName)
      columnData = columnData[columnName]
    } else {
      return
    }
  }
  return columnData
}

function buildFormula (formulaStr, data) {
  const columnNameList = getFormulaColumn(formulaStr)
  const columnData = getFormulaColumnValue(formulaStr, data)
  console.log('columnNameList', columnNameList)
  console.log('columnData', columnData)
  if (!columnData) {
    return
  }

  const columnExpr = '{' + columnNameList.join('.') + '}'
  console.log('columnExpr', columnExpr)
  if (typeof value === 'number') {
    formulaStr = formulaStr.replace(columnExpr, columnData)
  } else {
    formulaStr = formulaStr.replace(columnExpr, '"' + columnData + '"')
  }
  return formulaStr
}

const itemContents = [
  {
    value: '1',
    name: '自定义内容'
  },
  {
    value: '2',
    name: '关联已有数据'
  },
  {
    value: '3',
    name: '通过公式计算'
  }
]

const appTypes = [
  { id: 'T01', name: '普通表单' },
  { id: 'T02', name: '流程表单' },
  { id: 'T03', name: '报表' },
]

const isIos = () => {
  const u = navigator.userAgent
  return !!u.match(/\(i[^]+( U)? CPU.+Mac OS X/)
}
function getUrlPrefix () {
  return window.location.protocol + '//' + window.location.host
}

export default {
  c,
  message,
  formatToDate,
  formatToTime,
  formatTime,
  deepCopy,
  getTreeData,
  schemaColumnsToLine,
  getWorkflowAuthColumns,
  getWorkflowFilters,
  getWorkflowClearFilters,
  getDataflowFilters,
  getDataflowClearFilters,
  getSchemaColumnByID,
  getSchemaFlattenColumns,
  getSchemaDetailColumns,
  getNodeResultFlttenSchemaColumn,
  // getNodeSchemas,
  itemContents,
  getFormulaColumn,
  getFormulaColumnValue,
  buildFormula,
  appTypes,
  getUrlPrefix,
  xid,
  getDate,
  getDate2,
  isIos,
  isWeiXin,
  isMobile,
}
