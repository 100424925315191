import axios from 'axios'
import getObj from './func.js'

var prefix = '/api/v1/'

const app = {
  postApp (data) {
    var api = prefix + 'apps'
    return axios.post(api, data)
  },
  putApp (data) {
    var api = prefix + 'apps'
    return axios.put(api, data)
  },
  getApps () {
    return axios.get(prefix + 'apps')
  },
  getAppsSchemas (data) {
    return axios.get(prefix + 'apps/schemas' + getObj(data).url)
  },
  // 删除表单
  deleteApps (id) {
    var api = `/api/v1/apps?app_id=${id}`
    return axios.delete(api)
  }
}

export default app
