import { getMethodExcel, postExcel } from './excel.js'

const report = {
  // 导出模板
  exportTemplate (id, name) {
    getMethodExcel(`/api/v1/excel/title?schema_id=${id}`, name)
  },
  // 导出数据
  exportData (name, data) {
    postExcel('/api/v1/excel/content', name, data)
  }
}

export default report
