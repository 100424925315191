import axios from 'axios'

const data = {
  // 新增表单里面的数据
  postFormItem (data) {
    var api = '/api/v1/data'
    return axios.post(api, data)
  },
  postFormItemReport (data) {
    var api = '/api/v1/data/report'
    return axios.post(api, data)
  },
  postFormItemReportList () {
    var api = '/api/v1/data/report/list'
    return axios.post(api)
  },
  postFormItemReportCount () {
    var api = '/api/v1/data/report/count'
    return axios.post(api)
  },

}

export default data
