import axios from 'axios'

var prefix = '/api/v1/'

const schema = {
  // 发送表单请求
  postForm (data) {
    var api = prefix + 'schemas'
    return axios.post(api, data)
  },
  // 获取表单列表
  getForm () {
    return axios.get(prefix + 'schemas')
  },
  getSchema (schemaID, type) {
    var api = `/api/v1/schema?schema_id=${schemaID}&type=${type}`
    return axios.get(api)
  },
  async getSchemaSync (schemaID, type) {
    var api = `/api/v1/schema?schema_id=${schemaID}&type=${type}`
    return await axios.get(api)
  },
  getSchemas () {
    return axios.get(prefix + 'schemas')
  },
  // 获取单个表单数据
  getSoleData (id) {
    var api = `/api/v1/schema?schema_id=${id}`
    return axios.get(api)
  },
  getSoleData2 (id) {
    var api = `/api/v1/schema/form?schema_id=${id}`
    return axios.get(api)
  },
  // 修改表单
  putForm (id, data) {
    var api = `/api/v1/schema?schema_id=${id}`
    return axios.put(api, data)
  },

  // 修改表单
  putInfoForm (id, data) {
    var api = `/api/v1/schema/info?schema_id=${id}`
    return axios.put(api, data)
  },

  // 修改表单
  pushForm (id) {
    var api = `/api/v1/schema/push?schema_id=${id}`
    return axios.put(api)
  },

  // 修改表单
  cencelForm (id) {
    var api = `/api/v1/schema/cencel?schema_id=${id}`
    return axios.delete(api)
  },

  // 修改表单
  getStarForm () {
    var api = '/api/v1/schemas/stars'
    return axios.get(api)
  },
  // 修改表单
  getStarForm2 (id) {
    var api = `/api/v1/schemas/star?schema_id=${id}`
    return axios.get(api)
  },
  // 修改表单
  postStarForm (data) {
    var api = '/api/v1/schemas/star'
    return axios.post(api, data)
  },
  // 删除表单
  deleteForm (id) {
    var api = `/api/v1/schema?schema_id=${id}`
    return axios.delete(api)
  }
}

export default schema
