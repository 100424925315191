import axios from 'axios'
import router from '@/router'
import store from '@/store'
import utils from '@/utils'
import { Message } from 'element-ui'
import { Notify } from 'vant'

import main from './main'
import user from './user'
import auth from './auth'
import team from './team'
import app from './app'
import schema from './schema'
import data from './data'
import node from './node'
import report from './report'
import group from './group'
import template from './template'
import feishu from './feishu'
import callback from './callback'
import upload from './upload'
import workflow from './workflow'
import dataflow from './dataflow'
import record from './record'
import activity from './activity'

axios.defaults.baseURL = ''

axios.interceptors.request.use(function (config) {
  const token = store.getters.token
  if (token) {
    config.headers.Authorization = 'Bearer ' + token
  }
  return config
}, function (error) {
  return Promise.reject(error)
})

axios.interceptors.response.use(function (response) {
  return response
}, function (error) {
  const resp = error.response
  if (resp.status === 401) {
    if (utils.isWeiXin() || utils.isMobile()) {
      Notify({ type: 'danger', message: resp.data.error })
    } else {
      Message.error({ message: resp.data.message })
    }
    store.commit('removeToken')
    store.commit('removeProfile')
    store.commit('removeTeam')
    router.push({ name: 'login', query: { type: '401' } })
  } else if (resp.status === 400 || resp.status === 403) {
    if (utils.isWeiXin() || utils.isMobile()) {
      // Notify({ type: 'danger', message: resp.data.error })
      Notify({ type: 'danger', message: resp.data.message })
    } else {
      Message.error({ message: resp.data.message })
    }
  } else if (resp.status === 404) {
    if (utils.isWeiXin() || utils.isMobile()) {
      Notify({ type: 'danger', message: '访问数据不存在' })
    } else {
      Message.error({ message: '访问数据不存在' })
    }
  } else if (resp.status >= 500) {
    if (resp.status === 504) {
      if (utils.isWeiXin() || utils.isMobile()) {
        Notify({ type: 'danger', message: '连接服务器超时' })
      } else {
        Message.error({ message: '连接服务器超时' })
      }
    } else if (resp.status === 502) {
      if (utils.isWeiXin() || utils.isMobile()) {
        Notify({ type: 'danger', message: '服务器忙,请重试' })
      } else {
        Message.error({ message: '服务器忙,请重试' })
      }
    } else {
      if (utils.isWeiXin() || utils.isMobile()) {
        Notify({ type: 'danger', message: '服务器内部异常' })
      } else {
        Message.error({ message: '服务器内部异常' })
      }
    }
  }
  return Promise.reject(error)
})

function merge (...sources) {
  return Object.assign({}, ...sources)
}

const api = merge(main, team, user, auth, app, schema, data, node, report, group, template, feishu, callback, upload, workflow, dataflow, record, activity)

export {
  api
}
export {
  axios
}
