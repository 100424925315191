import axios from 'axios'

var prefix = '/feishu/open-apis/authen/v1/index?redirect_uri=http://kuaida.d.douwa.tech/auth/login&app_id=cli_a08c2bb57df0d00b&state=ok'

const feishu = {
  getfeishu (data) {
    return axios.post(prefix)
  }
}

export default feishu
