import axios from 'axios'
import getObj from './func.js'

const api = '/api/v1/workflow'

const workflow = {
  getWorkflow (schemaID) {
    return axios.get(api + getObj({ schema_id: schemaID }).url)
  },
  getWorkflowFirstNode (schemaID) {
    return axios.get(api + '/first_node' + getObj({ schema_id: schemaID }).url)
  },
  getWorkflowReleased (schemaID) {
    return axios.get(api + '/released' + getObj({ schema_id: schemaID }).url)
  },
  postWorkflow (schemaID, data) {
    return axios.post(api + getObj({ schema_id: schemaID }).url, data)
  },
  putWorkflow (schemaID, data) {
    return axios.put(api + getObj({ schema_id: schemaID }).url, data)
  },
  deleteWorkflow (schemaID) {
    return axios.delete(api + getObj({ schema_id: schemaID }).url)
  },
}

export default workflow
