import axios from 'axios'
import getObj from './func.js'

const main = {
  // 获取用户名 修改oauth新增的
  getUserName () {
    return axios.get('/api/v1/current/user/')
  },
  // 获取权限  修改oauth新增的
  getPermissionList (id) {
    // return axios.get('/newoauth/api/current/app/perms/')
    return axios.get('/api/v1/current/app/perms/?app_id=' + id)
  },
  // 获取全部用户列表
  getUsersList (data) {
    var aObj = getObj(data)
    var api = '/api/v1/app/users/' + aObj.url
    return axios.get(api)
  },
  // 获取全部权限列表
  getTeamUsers (data) {
    var aObj = getObj(data)
    var api = '/api/v1/team/user' + aObj.url
    return axios.get(api)
  }
}
export default main
