import axios from 'axios'
import router from '@/router'
import store from '@/store'

// aixos

export const http = axios.create({
  timeout: 10000 // request timeout
})

// 增加请求拦截器
http.interceptors.request.use(function (config) {
  // deal token
  const token = store.getters.token
  if (token) {
    config.headers.Authorization = 'Bearer  ' + token
  }

  return config
}, function (error) {
  console.log('request error:', error)
  return Promise.reject(error)
})

// 增加一个response拦截器
http.interceptors.response.use(function (response) {
  return response
}, function (error) {
  const routeName = router.app._route.name
  const res = error.response
  if (res && res.status === 401) {
    if (routeName !== '') {
      router.push({
        name: 'login',
        query: {
          next: routeName
        }
      })
    } else {
      router.push({
        name: 'login'
      })
    }
  }

  return Promise.reject(error)
})

export const apiObj = (obj) => {
  let url = ''
  for (const key in obj) {
    if (url.indexOf('?') === -1) {
      url += '?' + key + '=' + obj[key]
    } else {
      url += '&' + key + '=' + obj[key]
    }
  }
  return url
}

export default http
