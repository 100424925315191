import axios from 'axios'
import getObj from './func.js'

const apis = '/api/v1/dataflows'
const api = '/api/v1/dataflow'

const dataflow = {
  getDataflows (reqObj) {
    return axios.get(apis + getObj(reqObj).url)
  },
  postDataflow (data) {
    return axios.post(apis, data)
  },
  getDataflow (dataflowID) {
    return axios.get(api + '/' + dataflowID)
  },
  getDataflowByLast (dataflowID) {
    return axios.get(api + '/' + dataflowID + '/last')
  },
  putDataflow (dataflowID, data) {
    return axios.put(api + '/' + dataflowID, data)
  },
  deleteDataflow (dataflowID) {
    return axios.delete(api + '/' + dataflowID)
  },
  runDataflow (dataflowID) {
    return axios.post(api + '/' + dataflowID + '/run')
  },
}

export default dataflow
