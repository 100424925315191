import axios from 'axios'
import getObj from './func.js'

var prefix = '/api/v1/auth/'

const auth = {
  login (data) {
    return axios.post(prefix + 'login', data)
  },
  feishulogin (data) {
    return axios.get(prefix + 'login/feishu')
  },
  getPhoneCode (data) {
    return axios.get(prefix + 'send_code/phone' + getObj(data).url)
  }
}

export default auth
