import axios from 'axios'

const getMethodExcel = function (url, name, code) {
  axios.get(
    url, { headers: { Authorization: 'beaber ' + localStorage.getItem('session') }, responseType: 'blob' })
    .then(res => {
      // 这里res.data是返回的blob对象
      var blob = new Blob([res.data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=utf-8' }) // application/vnd.openxmlformats-officedocument.spreadsheetml.sheet这里表示xlsx类型
      var downloadElement = document.createElement('a')
      var href = window.URL.createObjectURL(blob) // 创建下载的链接
      downloadElement.href = href
      downloadElement.download = `${name}.xls` // 下载后文件名
      document.body.appendChild(downloadElement)
      downloadElement.click() // 点击下载
      document.body.removeChild(downloadElement) // 下载完成移除元素
      window.URL.revokeObjectURL(href) // 释放掉blob对象
    })
}

const postExcel = function (url, name, data) {
  axios.post(url, data, { headers: { Authorization: 'beaber ' + localStorage.getItem('session') }, responseType: 'blob' })
    .then(res => {
      // 这里res.data是返回的blob对象
      console.log(res.headers['content-type'])
      var blob = new Blob([res.data], {
        type: 'application/vnd.ms-excel'
      })
      var downloadElement = document.createElement('a')
      var href = window.URL.createObjectURL(blob)
      downloadElement.href = href
      downloadElement.download = `${name}.xls`
      document.body.appendChild(downloadElement)
      downloadElement.click()
      document.body.removeChild(downloadElement)
      window.URL.revokeObjectURL(href)
    })
}

export {
  getMethodExcel,
  postExcel
}
