import axios from 'axios'
import getObj from './func.js'

var prefix = '/api/v1/'

const template = {
  getTemplates (data) {
    return axios.get(prefix + 'templates' + getObj(data).url)
  },
  getTemplate (data) {
    return axios.get(prefix + 'template' + getObj(data).url)
  },
  postTemplate (data) {
    return axios.post(prefix + 'template' + getObj(data).url)
  },
  getCate () {
    return axios.get(prefix + 'templates/cates')
  }
}

export default template
