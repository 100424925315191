import axios from 'axios'

const api = '/api/v1/record'

const record = {
  searchRecord (searchObj) {
    return axios.post('/api/v1/records/search', searchObj)
  },
  createRecord (data) {
    return axios.post(api, data)
  },
  updateRecord (data) {
    return axios.post(api, data)
  },
  getRecord (recordID) {
    return axios.get(api + '/' + recordID)
  },
  deleteRecord (recordID) {
    return axios.delete(api + '/' + recordID)
  },
  getRecordWorkflow (recordID) {
    return axios.get(api + '/' + recordID + '/workflow')
  },
  actionRecordWorkflow (recordID, action) {
    return axios.post(api + '/' + recordID + '/workflow/' + action)
  },
}

export default record
