import axios from 'axios'

var prefix = '/api/v1/callback/'

const auth = {
  callback_event (data) {
    return axios.post(prefix + 'event', data)
  },
  callback_login (data) {
    return axios.post(prefix + 'login', data)
  }
}

export default auth
