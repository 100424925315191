import Vue from 'vue'
import Vuex from 'vuex'
import storage from '@/storage'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    team: storage.getTeam(),
    profile: storage.getProfile(),
    token: storage.getToken()
  },
  mutations: {
    removeTeam (state) {
      storage.removeTeam()
      state.team = storage.getTeam()
    },
    setTeam (state, team) {
      storage.setTeam(team)
      state.team = team
      // init team in dougo
      // api.dougo.initProject(team.name)
    },
    removeProfile (state) {
      storage.removeProfile()
      state.profile = storage.getProfile()
    },
    setProfile (state, profile) {
      storage.setProfile(profile)
      state.profile = profile
    },
    removeToken (state) {
      storage.removeToken()
      state.token = storage.getToken()
    },
    setToken (state, token) {
      storage.setToken(token)
      state.token = token
    }
  },
  getters: {
    team: state => state.team,
    profile: state => state.profile,
    token: state => state.token,
  }
})
