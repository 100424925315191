import axios from 'axios'

const node = {
  // 新增
  postNode (data) {
    var api = '/api/v1/node'
    return axios.post(api, data)
  }
}

export default node
