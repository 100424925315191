import axios from 'axios'

var prefix = '/api/v1/activity/'

const activity = {
  getActivitys (data) {
    return axios.get(prefix + 'list')
  },
  getActivityAddresss (id) {
    return axios.get(prefix + 'address?id=' + id)
  },
  getActivity (id) {
    return axios.get(prefix + '?id=' + id)
  },
  ActYuyue (data) {
    return axios.post(prefix, data)
  },
  getUserCount (id, addressId) {
    return axios.get(prefix + 'count?id=' + id + '&address_id=' + addressId)
  },
  getExt (id) {
    return axios.get(prefix + 'ext?id=' + id)
  },
  deleteAct (id, addressId) {
    return axios.delete(prefix + '?id=' + id)
  },
}

export default activity
