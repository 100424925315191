import axios from 'axios'
import getObj from './func.js'

var prefix = '/api/v1/'

const upload = {
  getImageUrl (data) {
    return axios.get(prefix + 'images' + getObj(data).url)
  }
}

export default upload
