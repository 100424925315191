import axios from 'axios'
import getObj from './func.js'

var prefix = '/api/v1/user/'

const user = {
  getUserCurrent () {
    return axios.get(prefix + 'current')
  },
  getEmailCode (data) {
    return axios.get(prefix + 'send_code/email' + getObj(data).url)
  },
  changeTitleCurrent (data) {
    return axios.put(prefix + 'change/title/current', data)
  },
  changeEmailCurrent (data) {
    return axios.put(prefix + 'change/email/current', data)
  },
  changePhoneCurrent (data) {
    return axios.put(prefix + 'change/phone/current', data)
  },
  changePasswordCurrent (data) {
    return axios.put(prefix + 'change/password/current', data)
  },
  changeUserGroup (data) {
    return axios.put(prefix + 'change/group', data)
  },
  getUserGroup (data) {
    return axios.get(prefix + 'group' + getObj(data).url)
  },
  getUserTeams () {
    return axios.get(prefix + 'teams')
  }
}

export default user
