import axios from 'axios'
import getObj from './func.js'

var prefix = '/api/v1/team'

const team = {
  initTeam (data) {
    return axios.post(prefix + '/init', data)
  },
  getTeamUsers (data) {
    return axios.get(prefix + '/user' + getObj(data).url)
  },
  getGroups () {
    return axios.get(prefix + '/groups')
  },
  deleteTeamUser (data) {
    return axios.delete(prefix + '/user' + getObj(data).url)
  },
  addTeamUser (data) {
    return axios.post(prefix + '/user' + getObj(data).url)
  },
  addTeamUser2 (data) {
    return axios.post(prefix + '/user2' + getObj(data).url)
  },
  changeTeam (data) {
    return axios.post(prefix + '/change' + getObj(data).url)
  },
  putTeam (data) {
    return axios.put(prefix, data)
  }
}

export default team
