const appName = '清真寺'

const routers = {
  mode: 'history',
  routes: [
    {
      name: 'site',
      path: '/site',
      component: () => import(/* webpackChunkName: "form" */ '@/views/site.vue')
    },
    {
      name: 'layout',
      path: '/',
      component: () => import(/* webpackChunkName: "form" */ '@/views/layout.vue'),
      redirect: '/home',
      children: [
        {
          name: 'home',
          path: 'home',
          meta: { title: '活动列表' },
          component: () => import(/* webpackChunkName: "form" */ '@/views/home.vue')
        },
        {
          name: 'user',
          path: 'user',
          meta: { title: '我的' },
          component: () => import(/* webpackChunkName: "mobile" */ '@/views/user.vue')
        }
      ]
    },
    {
      name: 'activity',
      path: '/activity',
      component: () => import(/* webpackChunkName: "form" */ '@/views/activity/index.vue'),
      redirect: '/activity/info/:id',
      children: [
        {
          name: 'activity_info',
          path: 'info/:id',
          meta: { title: '活动详情' },
          component: () => import(/* webpackChunkName: "form" */ '@/views/activity/info.vue')
        },
        {
          name: 'activity_user',
          path: 'user',
          meta: { title: '我的' },
          component: () => import(/* webpackChunkName: "mobile" */ '@/views/activity/user.vue')
        }
      ]
    },
    {
      name: 'auth',
      path: '/auth',
      component: () => import(/* webpackChunkName: "auth" */ '@/views/auth/index.vue'),
      redirect: '/auth/login',
      children: [
        {
          name: 'login',
          path: 'login',
          component: () => import(/* webpackChunkName: "auth" */ '@/views/auth/login.vue')
        },
      ]
    },
    {
      name: 'f',
      path: '/f',
      component: () => import('@/views/f.vue')
    },
    {
      path: '*',
      component: () => import('@/views/page404.vue')
    },
  ]
}

export default routers
