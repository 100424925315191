import axios from 'axios'
import getObj from './func.js'

var prefix = '/api/v1/'

const group = {
  postGroup (data) {
    var api = prefix + 'group'
    return axios.post(api, data)
  },
  // getGroups () {
  //   return axios.get(prefix + 'group')
  // },
  // getGroups () {
  //   return axios.get(prefix + 'group/all')
  // },
  getGroupByPermission () {
    return axios.get(prefix + 'group/permission')
  },
  getGroupByPermission2 (data) {
    return axios.get(prefix + 'group/permission2' + getObj(data).url)
  },
  getGroupSchemaByPermission (data) {
    return axios.get(prefix + 'group/permission/schema' + getObj(data).url)
  },
  changePerm (data) {
    return axios.put(prefix + 'group/permission', data)
  },
  getUserByGroup (data) {
    return axios.get(prefix + 'group/user' + getObj(data).url)
  },
  deleteGroup (data) {
    return axios.delete(prefix + 'group' + getObj(data).url)
  },
  addGroupUser (data) {
    return axios.post(prefix + 'group/user' + getObj(data).url)
  },
  deleteGroupUser (data) {
    return axios.delete(prefix + 'group/user' + getObj(data).url)
  }
}

export default group
