export default {
  setTeam (team) {
    localStorage.setItem('team', JSON.stringify(team))
  },
  getTeam () {
    let team = localStorage.getItem('team', undefined)
    if (team) {
      team = JSON.parse(team)
    }
    return team
  },
  removeTeam () {
    localStorage.removeItem('team')
  },
  setProfile (profile) {
    localStorage.setItem('profile', JSON.stringify(profile))
  },
  getProfile () {
    let profile = localStorage.getItem('profile', undefined)
    if (profile) {
      profile = JSON.parse(profile)
    }
    return profile
  },
  removeProfile () {
    localStorage.removeItem('profile')
  },
  setToken (token) {
    localStorage.setItem('token', token)
  },
  getToken () {
    return localStorage.getItem('token', undefined)
  },
  removeToken () {
    localStorage.removeItem('token')
  },
  setLang (lang) {
    localStorage.setItem('lang', lang)
  },
  getLang () {
    let lang = localStorage.getItem('lang', undefined)
    if (!lang) {
      const navLang = navigator.language
      lang = (navLang === 'zh-CN' || navLang === 'en-US' || navLang === 'vi') ? navLang : 'en-US'
    }

    return lang
  }
}
